import { //DATA_TYPE_EVENT_CATEGORIES,
DATA_TYPE_EXHIBITORS //DATA_TYPE_EXHIBITOR_CATEGORIES,
, DATA_TYPE_EVENTS, DATA_TYPE_CONTESTS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_SPEAKERS //DATA_TYPE_SERVICES,
, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_USERPROJECTS, DATA_TYPE_GOLDENSUMMIT_CATEGORIES //DATA_TYPE_USERPROJECT_CATEGORIES,
, DATA_TYPE_JOBOFFER_CATEGORIES, DATA_TYPE_JOBOFFERS, DATA_TYPE_NEWPRODUCT_CATEGORIES } from 'data/config/dataConfig';
import config from 'data/config/config';
import { LIST_PAGE_KEY, LIST_GROUPS_PAGE_KEY // LOGIN_PAGE_KEY,
, MAP_PAGE_KEY, MEDIAS_PAGE_KEY, SEARCH_TAIGA_PAGE_KEY, INBOX_PAGE_KEY, NOTES_PAGE_KEY } from 'src/pages/pagesKeys';
import AdSwap from 'src/components/ad-swap/AdSwap';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
import { openUrl } from 'src/core/util/JsTools';
import * as Query from 'src/core/query/Query';
import { AD_BUNDLE_ATTRIBUTION_KEYS } from 'src/core/config-json/ConfigJsonManager';
import {
/*  get as getLabels,  */
getCurrent as getLang } from 'src/core/Lang';
var LOG_PREF = '[homeConfig] '; // const { DEFAULT_PROFILE } = require('./profiles');
// NB: used for ChooseProfile page too !

export var getStyle = function getStyle() {
  return {
    background: "url(".concat(getUrl('files/project/home/2000x2000px_AppLeni_BG_MOParis.png'), ") no-repeat"),
    backgroundSize: 'contain'
  };
};
export var BASE_COLUMN_COUNT = {
  landscape: 3,
  portrait: 3
};
export var MAX_COLUMN_SIZE = {
  landscape: 190,
  // 2 columns mode => less width available
  portrait: 220
};
export var GENERAL_TILE_OPTIONS = {
  height: 1,
  // rowspan
  width: 1 // colspan

}; // const tileBgGradient = 'linear-gradient(rgba(17, 17, 17, 0.65), rgba(85, 85, 85, 0.65))'

var getServiceIdByLumpMainId = function getServiceIdByLumpMainId(main_id, dataType) {
  var items = Query.getTopCategories(dataType);
  var parentId;
  items && items.map(function (item) {
    if (item.lump.main_id === main_id) {
      parentId = item.id;
    }
  });
  return parentId;
};

var createItemAd = function createItemAd(homebutton, lang, backgroundPosition) {
  return createItem( // Style
  {
    container: {
      style: {},
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl(homebutton["img_".concat(lang)]), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  homebutton["label_".concat(lang)], // Action to perform on click
  function (actions) {
    var url = homebutton["link_".concat(lang)],
        target = homebutton["target"];

    if (openUrl(url, null, target, true)) {
      actions.linkClicked(url);
    }
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

function getParticipantsTile() {
  if (config.NETWORKING.FEATURE_ENABLED !== true) {
    return null;
  }

  var isAllMode = config.NETWORKING.PARTICIPANTS_DATA_MODE === 'ALL';
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Mise-en-relation.svg'), ")")
      }
    }
  }, // Label (i18n)
  'home.miseEnRelation', // Page & props
  isAllMode ? LIST_PAGE_KEY : SEARCH_TAIGA_PAGE_KEY, isAllMode ? {
    inputs: [{
      dataType: DATA_TYPE_PARTICIPANTS
    }]
  } : {
    dataType: DATA_TYPE_PARTICIPANTS,
    isAdvanced: false
  }, // Tile options
  GENERAL_TILE_OPTIONS);
}

var getEventsTile = function getEventsTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Programme.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.programme', // Page & props
  // LIST_GROUPS_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  // { inputs: [{ dataType: DATA_TYPE_EVENTS }] },
  LIST_GROUPS_PAGE_KEY, {
    input: {
      dataType: DATA_TYPE_EVENTS
    }
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getServicesTile = function getServicesTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Infos_Pratiques.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.practicalInfo', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      id: getServiceIdByLumpMainId('_BY_SERVICE_', DATA_TYPE_SERVICE_CATEGORIES),
      dataType: DATA_TYPE_SERVICE_CATEGORIES
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

function getNewsTile(lang) {
  /*  createItemRoute(
    // Style
    {
      container: {
        className: 'home-btn-text',
      },
      icon: {
        style: {
          backgroundImage: `url(${getUrl('files/project/home/Actualites.svg')})`,
        },
        // className: 'home-icon-programme',
      },
    },
     // Label (i18n)
    'home.news',
     // Page & props
    LIST_PAGE_KEY,
    // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
    {
      inputs: [
        {
          parentId: getServiceIdByLumpMainId('_BY_ACTUALITE_', DATA_TYPE_SERVICE_CATEGORIES),
          parentType: DATA_TYPE_SERVICE_CATEGORIES,
          dataType: DATA_TYPE_SERVICES,
        },
      ],
    },
     // Tile options
    GENERAL_TILE_OPTIONS
  ); */
  return createItem( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/actualites.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.news', // Action to perform on click
  function () {
    if (openUrl(config.NEWS_LINK[lang], null, config.NEWS_LINK_TARGET, config.NEWS_LINK_OPENININAPPBROWSER)) {
      actions.linkClicked(config.NEWS_LINK[lang]);
    }
  }, // Tile options
  GENERAL_TILE_OPTIONS
  /* {
        height:
          window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation]
            ? 0.6
            : 0.9,
        width:
          window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 2 : 1,
      } */
  );
}

var getExhibitorsTile = function getExhibitorsTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/exposants.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.exhibitors', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_EXHIBITORS
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getMarketPlaceTile = function getMarketPlaceTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Market_Place.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.marketplace', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_NEWPRODUCTS
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getGoldenSummitTile = function getGoldenSummitTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Sommet_Or.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.goldensummit', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      dataType: DATA_TYPE_GOLDENSUMMIT_CATEGORIES
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getAnimalsTile = function getAnimalsTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Carrefour_Gen.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.animals', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getContestsTile = function getContestsTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Concours_Animaux.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.contests', // Page & props
  LIST_GROUPS_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    hasFilter: true,
    input: {
      dataType: DATA_TYPE_CONTESTS
    }
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getUserVisitsTile = function getUserVisitsTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Visites_Ferme.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.userVisits', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getJobOffersTile = function getJobOffersTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Offres_Emploi.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.jobOffers', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_JOBOFFERS
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getUserProjectsTile = function getUserProjectsTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Projets_Visiteurs.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.userProjects', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_USERPROJECTS
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getFoodBelevageTile = function getFoodBelevageTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Food_Beverage.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.foodBeverage', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getWeatherTile = function getWeatherTile() {
  return createItem( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Meteo.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.weather', function (actions) {
    if (openUrl(config.WEATHER_LINK[getLang()], null, config.WEATHER_LINK_TARGET, config.WEATHER_LINK_OPENININAPPBROWSER)) {
      actions.linkClicked(config.WEATHER_LINK[getLang()]);
    }
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getNotesTile = function getNotesTile() {
  return createItemRoute( // style:
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Notes.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // label:
  'home.notes', // page key:
  NOTES_PAGE_KEY, null, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getSpeakersTile = function getSpeakersTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Intervenants.svg'), ")")
      } // className: 'home-icon-intervenants',

    }
  }, // Label (i18n)
  'home.speakers', // Page & props
  LIST_PAGE_KEY, {
    contextualTitle: '',
    inputs: [{
      dataType: DATA_TYPE_SPEAKERS
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getSocialMediaTile = function getSocialMediaTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Reseaux_Sociaux.svg'), ")")
      }
    }
  }, // Label (i18n)
  'home.medias', // Page & props
  MEDIAS_PAGE_KEY, // page props:
  {
    controlMediaPageContent: {
      hideTabMediaSocial: true // mediaTabKey: 'twitter',

    }
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

function getEbadgeTile(lang) {
  return createItem( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/badge.svg'), ")")
      }
    }
  }, // Label (i18n)
  'home.ebadge', // Action to perform on click
  function (actions) {
    if (openUrl(config.BADGE_LINK[lang], null, config.BADGE_LINK_TARGET, config.BADGE_LINK_OPENININAPPBROWSER)) {
      actions.linkClicked(config.BADGE_LINK[lang]);
    }
  }, // Tile options
  GENERAL_TILE_OPTIONS
  /* {
      height:
        window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation]
          ? 0.6
          : 0.9,
      width:
        window.innerWidth < MAX_COLUMN_SIZE[orientation] * BASE_COLUMN_COUNT[orientation] ? 2 : 1,
    } */
  );
}

var getMapTile = function getMapTile() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Plan.svg'), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  'home.map', // Page & props
  MAP_PAGE_KEY, null, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getNotifications = function getNotifications() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Notifications.svg'), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  'home.notifications', // Page & props
  INBOX_PAGE_KEY, null, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getVideos = function getVideos() {
  return createItemRoute( // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Youtube.svg'), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  'home.videos', // Page & props
  MEDIAS_PAGE_KEY, {
    controlMediaPageContent: {
      // hideTabMediaSocial: true,
      mediaTabKey: 'youtube' // to match index of social Media

    }
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getDefault = function getDefault(lang, adConfig, orientation, isLoggedIn) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar,
    props: {
      // Black list of buttons just to avoid side effect on older projects
      disabledButtons: ['inbox']
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [// Exposants
      getExhibitorsTile(), // MarketPlace
      getMarketPlaceTile(), // Programme
      getEventsTile(), // PLAN
      getMapTile(), // Sommet d’or
      getGoldenSummitTile(), // Carrefour de la Génétique
      // getAnimalsTile(),
      // Concours Animaux
      getContestsTile(), // Visite élevage
      //  getUserVisitsTile(),
      // Offres d’emploi
      getJobOffersTile(), // Projet visiteur
      //getUserProjectsTile(),
      // Food Belevage
      //getFoodBelevageTile(),
      // Mise en relation
      // getParticipantsTile(),
      // Actualites
      getNewsTile(lang), // INFOS PRATIQUES
      getServicesTile(), // Badges
      // getEbadgeTile(lang),
      // Social Medias
      getSocialMediaTile(), // Weather
      getWeatherTile(), // Notes
      config.NOTES && config.NOTES.FEATURE_ENABLED ? getNotesTile() : null // Videos
      // getVideos(),
      // INTERACTIVITE ATELIERS
      // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
      ]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
/* const getVisitor = (lang, adConfig, orientation, isLoggedIn) => {
  const hasAdButtons =
    adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';

  return [
    {
      component: AdSwap,
      props: {
        adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER,
      },
    },
    {
      component: HomeToolbar,
      props: {
        // Black list of buttons just to avoid side effect on older projects
        disabledButtons: ['inbox'],
      },
    },
    {
      component: HomeTiles,
      props: {
        baseColumnCount: BASE_COLUMN_COUNT[orientation],
        maxColumnSize: MAX_COLUMN_SIZE[orientation],
        tiles: [
          // Exposants
          getExhibitorsTile(),

          // MarketPlace
          getMarketPlaceTile(),

          // Programme
          getEventsTile(),

          // PLAN
          getMapTile(),

          // Sommet d’or
          getGoldenSummitTile(),

          // Carrefour de la Génétique
          getAnimalsTile(),

          // Concours Animaux
          getContestsTile(),

          // Visite élevage
          getUserVisitsTile(),

          // Offres d’emploi
          getJobOffersTile(),

          // Projet visiteur
          //getUserProjectsTile(),

          // Food Belevage
          getFoodBelevageTile(),

          // Mise en relation
          // getParticipantsTile(),

          // Actualites
          getNewsTile(),

          // INFOS PRATIQUES
          getServicesTile(),

          // Badges
          // getEbadgeTile(lang),

          // Weather
          getWeatherTile(),

          // Social Medias
          getSocialMediaTile(),

          // Videos
          // getVideos(),
          // INTERACTIVITE ATELIERS
          // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
        ],
      },
    },
    {
      component: AdSwap,
      props: {
        adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME,
      },
    },
    {
      component: MobilespotBar,
    },
    {
      component: IphonexFlexMargin,
    },
  ];
};

const getExhibitor = (lang, adConfig, orientation, isLoggedIn) => {
  const hasAdButtons =
    adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';

  return [
    {
      component: AdSwap,
      props: {
        adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER,
      },
    },
    {
      component: HomeToolbar,
      props: {
        // Black list of buttons just to avoid side effect on older projects
        disabledButtons: ['inbox', 'contactScan'],
      },
    },
    {
      component: HomeTiles,
      props: {
        baseColumnCount: BASE_COLUMN_COUNT[orientation],
        maxColumnSize: MAX_COLUMN_SIZE[orientation],
        tiles: [
          // Exposants
          getExhibitorsTile(),

          // Programme
          getEventsTile(),

          // Speakers
          getSpeakersTile(),

          // PLAN
          getMapTile(),

          // Mise en relation
          getParticipantsTile(),

          // INFOS PRATIQUES
          getServicesTile(),

          // Notifications
          getNotifications(),

          // Social Medias
          getSocialMediaTile(),

          // Videos
          getVideos(),

          // INTERACTIVITE ATELIERS
          // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
        ],
      },
    },
    {
      component: AdSwap,
      props: {
        adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME,
      },
    },
    {
      component: MobilespotBar,
    },
    {
      component: IphonexFlexMargin,
    },
  ];
}; */

/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @param  {boolean} isLoggedIn
 * @return {object}
 */


export function get(profile, lang, adConfig, orientation, isLoggedIn) {
  switch (profile) {
    // PRO
    case 'default':
      return getDefault(lang, adConfig, orientation, isLoggedIn);
    // GRAND PUBLIC

    /*     case 'exhibitor':
      return getExhibitor(lang, adConfig, orientation, isLoggedIn); */

    default:
      if (!profile) {
        console.warn(LOG_PREF + 'No profile set yet');
      } else {
        console.error(LOG_PREF + 'Unsupported profile: ' + profile);
      }

  }
}