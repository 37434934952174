import * as config from 'data/config/config';

import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';

import {
  CONFIG_JSON_LOADED,
  HAS_NAVIGATED,
  KEYBOARD_TOGGLED,
  LANG_CHANGED,
  PROFILE_CHANGED,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
  USER_DATA_UPDATED,
  UPDATE_USER_DATA_REQUEST_STATUS,
  CONTACTS_UPDATED,
  SEARCH_PERFORMED,
  CLEAR_SEARCH_RESULTS,
  LISTS_FETCHED,
  SHOW_FILTER_DIALOG,
  HIDE_FILTER_DIALOG,
  ITEMS_FETCHED,
} from 'src/store/actionTypes';

import {
  configJsonLoaded,
  getPageCommonDefaultState,
  profileChanged,
  toggleMenu,
  togglePageAfterNavigation,
  updateKeyboardState,
  updateObject,
  updatePageState,
} from 'src/store/reducers/commons';

import { logout } from 'src/core/login/LoginService';

import { getContacts } from 'src/core/contacts/Contacts';

import { getUserData, getContactedParticipants } from 'src/core/user-data/UserDataService';

function getDefaultState() {
  const commonDefaultState = getPageCommonDefaultState(USER_DATA_PAGE_KEY);

  return {
    ...commonDefaultState,
    userData: getUserData(),
    contacts: getContacts(),
    tos: config.TOU_LINK[commonDefaultState.labels.id],
    tosLinkTarget: config.TOU_LINK_TARGET,
    tosLinkOpenInInAppBrowser: config.TOU_LINK_OPENININAPPBROWSER,
    externalResourceLinkTarget: config.USER_DATA.externalResourceLinkTarget,
    externalResourceLinkOpenInInAppBrowser: config.USER_DATA.externalResourceLinkOpenInInAppBrowser,
    logout,
    formSections: config.USER_DATA.SECTIONS,
    formStatus: {
      saving: false,
      error: null,
    },
    searchResults: null,
    userProjectsItems: {},
    isFilterVisible: false,
  };
}

export const _langChanged = (state, action) =>
  updateObject(state, {
    labels: action.labels,
    tos: config.TOU_LINK[action.labels.id],
  });

const _updateUserdata = (state, action) =>
  updateObject(state, {
    userData: action.userData,
    contacts: getContacts(),
    formStatus: {
      saving: false,
      error: null,
    },
  });

const _updateContacts = (state, action) =>
  updateObject(state, {
    contacts: getContacts(),
  });

function _searchPerformed(state, action) {
  if (action.pageKey !== USER_DATA_PAGE_KEY) {
    return state;
  }
  return updateObject(state, {
    searchResults: action.results,
  });
}

function _clearSearchResults(state, action) {
  if (action.pageKey !== USER_DATA_PAGE_KEY) {
    return state;
  }
  return updateObject(state, {
    searchResults: null,
  });
}

/**
 * Initial action for list page
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
function _listsFetched(state, action) {
  const { items } = action;
  return updateObject(state, {
    shouldFetch: false,
    userProjectsItems: items,
  });
}

function _itemsFetched(state, action) {
  return updateObject(state, {
    shouldFetch: false,
    userProjectsItems: items,
  });
}

function _toggleFilterDialog(state, isVisible) {
  return updateObject(state, {
    isFilterVisible: isVisible,
  });
}

function _updatePageState(state, action) {
  return updateObject(updatePageState(state, action, USER_DATA_PAGE_KEY), {
    hasFilter: !!action.props.hasFilter,
    filterEnabled: !!action.props.filterEnabled,
    shouldFetch: action.pageKey === USER_DATA_PAGE_KEY,
    filterCatsCount:
      typeof action.props.filterCatsCount === 'number' ? action.props.filterCatsCount : null,
  });
}

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);
    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, USER_DATA_PAGE_KEY);
    case KEYBOARD_TOGGLED:
      return updateKeyboardState(state, action);
    case LANG_CHANGED:
      return _langChanged(state, action);
    case PROFILE_CHANGED:
      return profileChanged(state, action, USER_DATA_PAGE_KEY);
    case TOGGLE_MENU:
      return toggleMenu(state, action, USER_DATA_PAGE_KEY);
    case UPDATE_PAGE_STATE:
      return _updatePageState(state, action);
    case USER_DATA_UPDATED:
      return _updateUserdata(state, action);
    case CONTACTS_UPDATED:
      return _updateContacts(state, action);
    case UPDATE_USER_DATA_REQUEST_STATUS:
      return updateObject(state, { formStatus: action.status });
    case SEARCH_PERFORMED:
      return _searchPerformed(state, action);
    case CLEAR_SEARCH_RESULTS:
      return _clearSearchResults(state, action);
    case LISTS_FETCHED:
      return _listsFetched(state, action);
    case ITEMS_FETCHED:
      return _itemsFetched(state, action);
    case SHOW_FILTER_DIALOG:
      return _toggleFilterDialog(state, true);
    case HIDE_FILTER_DIALOG:
      return _toggleFilterDialog(state, false);

    default:
      return state;
  }
};
