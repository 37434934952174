var defaultConf = require('./config-default');

var BO_URL = 'selV2-data-dev.mobile-spot.com'; // const BO_TAIGA_URL = 'https://sommet-elevage-2021.site.calypso-event.net';

var NODE_BACKEND_DOMAIN = 'sel-node-backend-dev.mobile-spot.com';
var NODE_WS_BAKEND_DOMAIN = 'sel-node-backend-ws-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/www/sommetelevage/sel/sel2021/selV2-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '/home/www/sommetelevage/sel/sel2021/sel-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-36',
  // LOGIN: {
  //   forgottenPasswordURL: `${BO_TAIGA_URL}/mobile-se/mot-de-passe-oublie.htm`,
  // },
  projectId: '120660147336',
  // FCM sender id
  appId: '06564-FC9EF',
  // pushwoosh appid
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    MODE: 'peering',
    // (peering or login) 
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/synchro-favorites")
  },
  REAL_TIME: {
    URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
  },
  SOCIAL: {
    FEATURE_ENABLED: false,

    /* TWITTER: {
      POST_PER_PAGE: 10,
    },
    YOUTUBE: {
      POST_PER_PAGE: 5,
    }, */
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social")
  }
});