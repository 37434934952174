import React from 'react';
import PropTypes from 'prop-types';

const CompanyRow = (props) =>
  !props.company ? null : (
    <div className="free-row">
      <div className="prop-img">
        <div className="prop-left">
          <span className="fa fa-building" />
        </div>
        <div
          className="prop-right"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <a className="link colored-link">{props.company}</a>
        </div>
      </div>
    </div>
  );

CompanyRow.propTypes = {
  company: PropTypes.string,
};

export default CompanyRow;
