import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';

import MediaTabItem from './MediaTabItem';
import MediaTab from './MediaTab';

import AdSwap from 'src/components/ad-swap/AdSwap';
import { getAdBundleAttributionKeyForPage } from 'src/core/config-json/ConfigJsonManager';
import { MEDIAS_PAGE_KEY } from 'src/pages/pagesKeys';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { isIOS, isSafari } from 'src/core/util/browser';
const tabHeight = 48;

class MediasPageContent extends Component {
  constructor(props) {
    super(props);
  }

  getIndexByMediaKeys = (key) => {
    return Object.keys(this.props.socialMediaConfig).indexOf(key);
  };

  state = {
    value: this.props.mediaTabKey ? this.getIndexByMediaKeys(this.props.mediaTabKey) : 0,
  };

  componentDidUpdate(prevProps) {
    prevProps.mediaTabKey !== this.props.mediaTabKey &&
      this.setState({ value: this.getIndexByMediaKeys(this.props.mediaTabKey) });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      nextState.value !== this.state.value
    );
  }

  handleChange = (event, value) => {
    const { socialMediaConfig } = this.props;

    this.setState({ value });

    const mediaKeys = Object.keys(socialMediaConfig);
    const platformClicked = mediaKeys[value];
    const platformConfig = socialMediaConfig[platformClicked];

    if (platformConfig.link) {
      window.open(platformConfig.link, '_system');
    }
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  render() {
    const { socialMediaConfig, actions } = this.props;
    const mediaKeys = Object.keys(socialMediaConfig);
    const contentStyle = isIOS() || isSafari() ? { height: 'auto' } : { height: '100%' };
    return (
      <div className="media-content-container content-font content-below-apptoolbar">
        <div className="media-timeline">
          {/* Tabs API: https://material-ui.com/api/tabs/ */}
          {!this.props.hideTabMediaSocial && (
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              variant="fullWidth"
              indicatorColor="primary"
            >
              {mediaKeys.map((platform, index) => {
                const config = socialMediaConfig[platform];
                const active = this.state.value === index;
                return (
                  <MediaTabItem key={platform} config={config} active={active} actions={actions} />
                );
              })}
            </Tabs>
          )}

          {/* SwipeableViews API: https://react-swipeable-views.com/api/api/ */}
          <SwipeableViews
            slideStyle={contentStyle}
            containerStyle={contentStyle}
            style={(isIOS() || isSafari()) && { display: 'flex', flexDirection: 'column' }}
            index={this.state.value}
            onChangeIndex={this.handleChangeIndex}
          >
            {mediaKeys.map((platform) => (
              <MediaTab
                key={platform}
                height="100%"
                platform={platform}
                config={socialMediaConfig[platform]}
                data={{
                  twitter: this.props.twitter,
                  facebook: this.props.facebook,
                  instagram: this.props.instagram,
                  youtube: this.props.youtube,
                  linkedin: this.props.linkedin,
                  tiktok: this.props.tiktok,
                }}
                actions={this.props.actions}
              />
            ))}
          </SwipeableViews>
        </div>
        <AdSwap
          adBundleAttributionKey={getAdBundleAttributionKeyForPage(MEDIAS_PAGE_KEY)}
          isPageVisible={this.props.isPageVisible}
          isAppVisible={this.props.isAppVisible}
        />
        <IphonexFlexMargin />
      </div>
    );
  }
}

MediasPageContent.propTypes = {
  socialMediaConfig: PropTypes.object.isRequired,
  twitter: PropTypes.object,
  facebook: PropTypes.object,
  instagram: PropTypes.object,
  youtube: PropTypes.object,
  linkedin: PropTypes.object,
  tiktok: PropTypes.object,
  adConfig: PropTypes.object,
  actions: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  isPageVisible: PropTypes.bool,
  isAppVisible: PropTypes.bool,
};

export default MediasPageContent;
