import React from 'react';
import PropTypes from 'prop-types';

const FullNameRow = (props) =>
  (props.lastname || props.firstname) && (
    <div className="free-row">
      <div className="prop-img">
        <div className="prop-left">
          <span className="fa fa-user" />
        </div>
        <div
          className="prop-right"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <a className="link colored-link">
            {props.civility ? props.civility : ''} {props.firstname ? props.firstname : ''}{' '}
            {props.lastname ? props.lastname : ''}
          </a>
        </div>
      </div>
    </div>
  );

FullNameRow.propTypes = {
  lastname: PropTypes.string,
  firstname: PropTypes.string,
  civility: PropTypes.string,
};

export default FullNameRow;
