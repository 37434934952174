import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_EVENTS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_SPEAKERS, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_VIDEOS, DATA_TYPE_VIDEO_CATEGORIES, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_GOLDENSUMMIT_CATEGORIES, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_JOBOFFER_CATEGORIES, DATA_TYPE_JOBOFFERS, DATA_TYPE_CONTESTS } from 'data/config/dataConfig';
import { createItem, createItemLang, createItemRoute, createItemToggleLocation, createItemTogglePMR } from 'src/core/util/ConfigItemFactory';
import * as Db from 'src/core/data-and-assets/Db';
import { startUpdate } from 'src/core/data-and-assets/Updater';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { isIOS, isAndroid, isMobile } from 'src/core/util/browser';
import { HOME_PAGE_KEY, FAVORITES_PAGE_KEY, INBOX_PAGE_KEY, KLIPSO_LEADS_PAGE_KEY, LIST_PAGE_KEY, MAP_PAGE_KEY, SEARCH_PAGE_KEY, MEDIAS_PAGE_KEY, CHOOSE_PROFILE_PAGE_KEY, LIST_GROUPS_PAGE_KEY, NOTES_PAGE_KEY, USER_DATA_PAGE_KEY, SEARCH_TAIGA_PAGE_KEY } from 'src/pages/pagesKeys';
import favIcon from 'data/public/icons/Fav.svg';
import searchIcon from 'data/public/icons/Search.svg';
import homeIcon from 'data/public/icons/Home.svg';
import KLipsoIcon from 'data/public/icons/KlipsoLead.svg';
import badgeIcon from 'data/public/icons/badge.svg';
import { openUrl } from 'src/core/util/JsTools';
import config from './config';
import * as Query from 'src/core/query/Query';
import { get as getLabels, getCurrent as getLang } from 'src/core/Lang';

var _require = require('./profiles'),
    DEFAULT_PROFILE = _require.DEFAULT_PROFILE,
    EXHIBITOR_PROFILE = _require.EXHIBITOR_PROFILE,
    WEBAPP_EXH_PROFILE = _require.WEBAPP_EXH_PROFILE,
    WEBAPP_EVT_PROFILE = _require.WEBAPP_EVT_PROFILE;

var LOG_PREF = '[menuConfig] '; // documentation: https://material-ui.com/api/swipeable-drawer/#props

export var MENU_OPTIONS = {
  anchor: 'right',
  swipeAreaWidth: isMobile() ? 10 : 0 // default 20

}; // see https://github.com/markusenglund/react-switch#api

export var LOCATION_BUTTON_PROPS = {
  onColor: '#86d3ff',
  onHandleColor: '#2693e6',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 12,
  width: 36
};
export var PMR_BUTTON_PROPS = {
  onColor: '#86d3ff',
  onHandleColor: '#2693e6',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 12,
  width: 36
};
var ICON_SIZE = '97%';
var MEDIUM_ICON_SIZE = '85%';
var SMALL_ICON_SIZE = '35%';
var MENU_ICON_COLOR = 'F39200';

var getServiceIdByLumpMainId = function getServiceIdByLumpMainId(main_id, dataType) {
  var items = Query.getTopCategories(dataType);
  var parentId;
  items && items.map(function (item) {
    if (item.lump.main_id === main_id) {
      parentId = item.id;
    }
  });
  return parentId;
};
/**
 * Generate a menu entry to route to Home page
 */


function getItemRouteHome(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(\"".concat(homeIcon.replace("fill='black'", "fill='%23".concat(MENU_ICON_COLOR, "'")), "\")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.home.title, // page key:
  HOME_PAGE_KEY);
}
/**
 * Generate a menu entry to route to the list of exhibitors
 */


function getItemRouteExhibitors(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/exposants.svg'), ")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.exhibitors, // page key:
  LIST_PAGE_KEY, {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_EXHIBITORS
    }]
  });
}
/**
 * Generate a menu entry to route to the list of Market Place
 */


function getItemRouteMarketPlaceTile(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Market_Place.svg'), ")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.marketplace, // page key:
  LIST_PAGE_KEY, {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_NEWPRODUCTS
    }]
  });
}
/**
 * Generate a menu entry to route to the list of Golden Summit
 */


function getItemRouteGoldenSummitTile(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Sommet_Or.svg'), ")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.goldensummit, // page key:
  LIST_PAGE_KEY, {
    inputs: [{
      dataType: DATA_TYPE_GOLDENSUMMIT_CATEGORIES
    }]
  });
}
/**
 * Generate a menu entry to route to the list of Contests
 */


function getItemRouteContestsTile(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Concours_Animaux.svg'), ")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.contests, // page key:
  LIST_GROUPS_PAGE_KEY, {
    hasFilter: true,
    input: {
      dataType: DATA_TYPE_CONTESTS
    }
  });
}
/**
 * Generate a menu entry to route to the list of Job Offers
 */


function getItemRouteJobOffersTile(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Offres_Emploi.svg'), ")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.jobOffers, // page key:
  LIST_PAGE_KEY, {
    hasFilter: true,
    inputs: [{
      dataType: DATA_TYPE_JOBOFFERS
    }]
  });
}
/**
 * Generate a menu entry to route to the list of videos
 */


function getItemRouteVideos(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Videos.svg'), ")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.videos, // page key:
  LIST_PAGE_KEY, // page props:
  {
    inputs: [{
      // id: getServiceIdByLumpMainId('_BY_SERVICE_', DATA_TYPE_SERVICE_CATEGORIES),
      dataType: DATA_TYPE_VIDEO_CATEGORIES
    }]
  });
}
/**
 * Generate a menu entry to route to the list of services
 */


function getItemRouteServices(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Infos_Pratiques.svg'), ")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.services, // page key:
  LIST_PAGE_KEY, // page props:
  {
    inputs: [{
      id: getServiceIdByLumpMainId('_BY_SERVICE_', DATA_TYPE_SERVICE_CATEGORIES),
      dataType: DATA_TYPE_SERVICE_CATEGORIES
    }]
  });
}
/**
 * Generate a menu entry to route to the list of services
 */


function getItemRouteNews(labels) {
  /* return createItemRoute(
    // style:
    {
      icon: {
        style: {
          backgroundImage: `url(${getUrl('files/project/home/Actualites.svg')})`,
          backgroundSize: MEDIUM_ICON_SIZE,
          backgroundPosition: 'center',
          lineHeight: '1.4em',
        },
      },
    },
    // label:
    labels.menu.news,
    // page key:
    LIST_PAGE_KEY,
    // page props:
    {
      inputs: [
        {
          parentId: getServiceIdByLumpMainId('_BY_ACTUALITE_', DATA_TYPE_SERVICE_CATEGORIES),
          parentType: DATA_TYPE_SERVICE_CATEGORIES,
          dataType: DATA_TYPE_SERVICES,
        },
      ],
    }
  ); */
  return createItem( // Style
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/actualites.svg'), ")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // Label (i18n)
  labels.menu.news, // Action to perform on click
  function () {
    if (openUrl(config.NEWS_LINK[getLang()], null, config.NEWS_LINK_TARGET, config.NEWS_LINK_OPENININAPPBROWSER)) {
      actions.linkClicked(config.NEWS_LINK[getLang()]);
    }
  });
}
/**
 * Generate a menu entry to route to participants
 */


function getItemRouteParticipants(labels) {
  if (config.NETWORKING.FEATURE_ENABLED !== true) {
    return null;
  }

  var isAllMode = config.NETWORKING.PARTICIPANTS_DATA_MODE === 'ALL';
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Mise-en-relation.svg'), ")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.miseEnRelation, // page key:
  isAllMode ? LIST_PAGE_KEY : SEARCH_TAIGA_PAGE_KEY, // page props:
  isAllMode ? {
    inputs: [{
      dataType: DATA_TYPE_PARTICIPANTS
    }]
  } : {
    dataType: DATA_TYPE_PARTICIPANTS,
    isAdvanced: false
  });
}
/**
 * Generate a menu entry to route to list of event categories
 */


function getItemRouteAgenda(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Programme.svg'), ")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.programme, // page key:
  LIST_GROUPS_PAGE_KEY, {
    input: {
      dataType: DATA_TYPE_EVENTS
    }
  });
}
/**
 * Generate a menu entry to route to the list of speakers
 */


function getItemRouteSpeakers(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Intervenants.svg'), ")"),
        backgroundSize: ICON_SIZE,
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.speakers, // page key:
  LIST_PAGE_KEY, // page props:
  {
    inputs: [{
      dataType: DATA_TYPE_SPEAKERS
    }]
  });
}
/**
 * Generate a menu entry to route to the list of papers categories
 */


function getItemRoutePapers(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Abstracts.svg'), ")"),
        backgroundSize: ICON_SIZE,
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.papers, // page key:
  LIST_PAGE_KEY, // page props:
  {
    inputs: [{
      dataType: DATA_TYPE_PAPER_CATEGORIES
    }]
  });
}
/**
 * Generate a menu entry to route to Map page
 */


function getItemRouteMap(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Plan.svg'), ")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.map, // page key:
  MAP_PAGE_KEY);
}
/**
 * Generate a menu entry to route to Notes page
 */


function getItemRouteNotes(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Notes.svg'), ")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.notes.pageLinkTitle, // page key:
  NOTES_PAGE_KEY);
}
/**
 * Generate a menu entry to route to Weather page
 */


function getItemRouteWeather(labels, actions) {
  return createItem( // Style
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Meteo.svg'), ")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // Label (i18n)
  labels.menu.weather, function () {
    if (openUrl(config.WEATHER_LINK[getLang()], null, config.WEATHER_LINK_TARGET, config.WEATHER_LINK_OPENININAPPBROWSER)) {
      actions.linkClicked(config.WEATHER_LINK[getLang()]);
    }
  });
}
/**
 * Generate a menu entry to route to global Search page
 */


function getItemRouteSearch(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(\"".concat(searchIcon.replace("fill='black'", "fill='%23".concat(MENU_ICON_COLOR, "'")), "\")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.search.title, // page key:
  SEARCH_PAGE_KEY);
}
/**
 * Generate a menu entry to route to Favorites page
 */


function getItemRouteFavorites(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(\"".concat(favIcon.replace("fill='black'", "fill='%23".concat(MENU_ICON_COLOR, "'")), "\")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.favorites.title, // page key:
  FAVORITES_PAGE_KEY);
}
/**
 * Generate a menu entry to route to Inbox page
 */


function getItemRouteInbox(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'far fa-envelope',
      style: {
        fontSize: '1.4em',
        fontWeight: '300',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.inbox, // page key:
  INBOX_PAGE_KEY);
}
/**
 * Generate a menu entry to route to Klipso Leads page
 */


function getItemRouteKlipsoLeads(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(\"".concat(KLipsoIcon.replace("fill='black'", "fill='%23".concat(MENU_ICON_COLOR, "'")), "\")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.klipsoLeads, // page key:
  KLIPSO_LEADS_PAGE_KEY);
}

function getItemRouteBadge(labels, actions) {
  return createItem( // Style
  {
    icon: {
      style: {
        backgroundImage: "url(\"".concat(badgeIcon.replace("fill='black'", "fill='%23".concat(MENU_ICON_COLOR, "'")), "\")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // Label (i18n)
  labels.menu.ebadge, // Action to perform on click
  function () {
    if (openUrl(config.BADGE_LINK[getLang()], null, config.BADGE_LINK_TARGET, config.BADGE_LINK_OPENININAPPBROWSER)) {
      actions.linkClicked(config.BADGE_LINK[getLang()]);
    }
  });
}
/**
 * Generate a menu entry to change app language
 */


function getItemLang(labels, actions) {
  return createItem( // style:
  {
    icon: {
      className: 'fa fa-lang bottom5',
      style: {
        fontSize: '1.3em',
        fontWeight: '300',
        lineHeight: '1.5em'
      }
    }
  }, // label:
  labels.menu.language, // function to execute:
  actions.showChooseLangDialog);
}
/**
 * Generate a menu entry to open TOU link
 */


var getActionTOU = function getActionTOU(labels, actions) {
  return createItem( // style:
  {
    icon: {
      className: 'fa fa-cgu',
      style: {
        lineHeight: '1.5em',
        fontSize: '1.2em',
        fontWeight: '300'
      }
    }
  }, // label:
  labels.menu.cgu, // function to execute:
  function () {
    if (openUrl(config.TOU_LINK[labels.id], null, config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER)) {
      actions.linkClicked(config.TOU_LINK[labels.id]);
    }
  });
};
/**
 * Generate a menu entry to open TOU link
 */


var getActionBadge = function getActionBadge(labels) {
  return createItem( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/badge.svg'), ")"),
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center',
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.ebadge, // page key:
  KLIPSO_LEADS_PAGE_KEY, // page props:
  null);
};

var getUserData = function getUserData(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'fa fa-user',
      style: {
        lineHeight: '1.4em'
      }
    }
  }, // label:
  labels.menu.userData, // page key:
  USER_DATA_PAGE_KEY, // page props:
  null);
};
/**
 * Generate a menu entry to route to Choose profile page
 */


function getItemRouteChooseProfile(labels, profile) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'fa fa-user'
    }
  }, // label:
  (profile ? "<div class=\"menu-text-current-profile\">".concat(profile, "</div>") : '') + labels.menu.toggleProfile, // page key:
  CHOOSE_PROFILE_PAGE_KEY, // Page props:
  null);
}
/**
 * Generate a menu entry using configuration from a config.json menubuttons item
 */


function getAdMenuButton(conf, labels, actions) {
  if (!conf) {
    return null;
  }

  return createItem( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl(conf["img_".concat(labels.id)]), ")"),
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  conf["label_".concat(labels.id)], // function to execute:
  function () {
    var url = conf["link_".concat(labels.id)];

    if (openUrl(url)) {
      actions.linkClicked(url);
    }
  });
}
/**
 * Generate a menu entry to route to Social Media page
 */


function getItemSocialMedia(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Reseaux_Sociaux.svg'), ")"),
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.medias, // page key:
  MEDIAS_PAGE_KEY, // page props:
  {
    controlMediaPageContent: {
      hideTabMediaSocial: true // mediaTabKey: 'twitter',

    }
  });
}
/**
 * Generate a menu entry to route to Video page
 */


function getItemVideos(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Youtube.svg'), ")"),
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.videos, // page key:
  MEDIAS_PAGE_KEY, // page props:
  {
    controlMediaPageContent: {
      // hideTabMediaSocial: true,
      mediaTabKey: 'youtube'
    }
  });
}
/**
 * Generate a button to toggle location
 */


function getItemToggleLocation(labels) {
  return createItemToggleLocation( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/location.svg'), ")"),
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.location);
}
/**
 * Generate a button to toggle PMR status (used for mobigeo routing)
 */


export function getItemTogglePMR(labels) {
  return createItemTogglePMR( // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/pmr.svg'), ")"),
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.pmr);
}
/**
 * Generate an item to open the current page in the mobile app
 * for deeplinking test purpose
 */

/* function getItemOpenCurrentPageInNativeApp() {
    return createItem(
        // style
        { icon: {
            className: 'fab fa-'+(isIOS() ? 'apple' : 'android'),
            style: {
                color: '#bababa',
                fontSize: '2em',
            }
        }},
        // label:
        'Open page in '+(isIOS() ? 'iOS' : 'android')+' app',
        // function to execute:
        openCurrentPageInApp,
    );
} */

/**
 * Menu configuration
 */
// const getConfig = (actions, labels, adConfig, twoColumns) => {
//     let conf = {
//         default: [
//             getItemRouteBrands(labels),
//             getItemRouteRestaurants(labels),
//             getItemRouteServices(labels),
//             getItemRouteHours(labels),
//             getItemRouteMap(labels),
//             // Generic:
//             getItemRouteSearch(labels),
//             getItemRouteFavorites(labels),
//             getItemRouteInbox(labels),
//             getItemLang(labels, actions),
//             config.ENV === 'dev' && global.isCordovaContext !== true ? getItemOpenCurrentPageInNativeApp() : null,
//         ]
//     };
//
//     if (adConfig && adConfig.menubuttons) {
//         Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
//             conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions) );
//         });
//     }
//
//     // Filter any empty entry
//     Object.keys(conf).forEach(function(profile) {
//         conf[profile] = conf[profile].filter(menuItemConfig => menuItemConfig);
//     });
//
//     return conf;
// };

/**
 * Menu configuration for default profile
 */

var getDefaultConfig = function getDefaultConfig(actions, labels, adConfig, profile) {
  var conf = {
    default: [// Home
    //getItemRouteHome(labels),
    // Exposants
    getItemRouteExhibitors(labels), // MarketPlace
    getItemRouteMarketPlaceTile(labels), // Programme
    getItemRouteAgenda(labels), // Plan
    getItemRouteMap(labels), // Sommet d’or
    getItemRouteGoldenSummitTile(labels), // Concours Animaux
    getItemRouteContestsTile(labels), // Offres d’emploi
    getItemRouteJobOffersTile(labels), // Actualites
    getItemRouteNews(labels), // INFOS PRATIQUES
    getItemRouteServices(labels), // VIDEOS
    getItemRouteVideos(labels), // Social Medias
    getItemSocialMedia(labels), // weather
    getItemRouteWeather(labels, actions), // Favorites
    getItemRouteFavorites(labels), // Notes
    config.NOTES && config.NOTES.FEATURE_ENABLED ? getItemRouteNotes(labels) : null, // Search
    getItemRouteSearch(labels), // Lang
    // getItemLang(labels, actions),
    // Notifications
    getItemRouteInbox(labels)]
  };

  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(function (menuBtnKey) {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }

  conf.default.push(getActionTOU(labels, actions));
  return conf;
};
/**
 * Menu configuration for GP profile
 */


var getWebAppExhConfig = function getWebAppExhConfig(actions, labels, adConfig, profile) {
  var conf = {
    default: [// Favorites
    getItemRouteFavorites(labels), // Notes
    config.NOTES && config.NOTES.FEATURE_ENABLED ? getItemRouteNotes(labels) : null, // Search
    getItemRouteSearch(labels), // Lang
    // getItemLang(labels, actions),
    // Notifications
    getItemRouteInbox(labels), getItemRouteExhibitors(labels), getItemRouteMap(labels) // getItemLang(labels, actions),
    // getItemSocialMedia(labels),
    ]
  };

  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(function (menuBtnKey) {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }

  return conf;
};
/**
 * Menu configuration for PRESS profile
 */


var getWebAppEvtConfig = function getWebAppEvtConfig(actions, labels, adConfig, profile) {
  var conf = {
    default: [// Favorites
    getItemRouteFavorites(labels), // Notes
    config.NOTES && config.NOTES.FEATURE_ENABLED ? getItemRouteNotes(labels) : null, // Search
    getItemRouteSearch(labels), // Lang
    // getItemLang(labels, actions),
    // Notifications
    getItemRouteInbox(labels), getItemRouteAgenda(labels), //getItemRouteSpeakers(labels),
    getItemRouteMap(labels)]
  };

  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(function (menuBtnKey) {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }

  return conf;
};
/**
 * Menu configuration for PRESS profile
 */

/* const getWebAppSpkConfig = (actions, labels, adConfig, profile) => {
  const conf = {
    default: [
      getItemRouteFavorites(labels),
      getItemRouteSpeakers(labels),
      getItemRouteAgenda(labels),
    ],
  };

  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach((menuBtnKey) => {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }

  return conf;
}; */

/**
 * Return menu configuration
 * @param  {string} profile
 * @return {object}
 */


export var get = function get() {
  var profile = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_PROFILE;
  var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';
  var actions = arguments.length > 2 ? arguments[2] : undefined;
  var labels = arguments.length > 3 ? arguments[3] : undefined;
  var adConfig = arguments.length > 4 ? arguments[4] : undefined;
  var twoColumns = arguments.length > 5 ? arguments[5] : undefined;
  var conf; // Get menu config depending on user profile

  switch (profile) {
    case DEFAULT_PROFILE:
      conf = getDefaultConfig(actions, labels, adConfig, labels.profile[profile]);
      break;

    case EXHIBITOR_PROFILE:
      conf = getDefaultConfig(actions, labels, adConfig, labels.profile[profile]);
      break;

    case WEBAPP_EXH_PROFILE:
      conf = getWebAppExhConfig(actions, labels, adConfig, labels.profile[profile]);
      break;

    case WEBAPP_EVT_PROFILE:
      conf = getWebAppEvtConfig(actions, labels, adConfig, labels.profile[profile]);
      break;
    // case WEBAPP_SPK_PROFILE :
    //     conf = getWebAppSpkConfig(actions, labels, adConfig, labels.profile[profile]);
    //     break;

    default:
      console.error("".concat(LOG_PREF, "Unsupported profile"), profile);
  } // Return config depending on page


  if (conf) {
    // Filter any empty entry
    Object.keys(conf).forEach(function (page) {
      conf[page] = conf[page].filter(function (menuItemConfig) {
        return menuItemConfig;
      });
    });
    return conf[page] || conf.default;
  } // else undefined is returned

};